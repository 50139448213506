var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"additionalValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('br'),_c('h2',{staticClass:"shipment_create_header"},[_vm._v("Airfreight Cost")]),_c('b-form-group',{attrs:{"label":"Airfreight Cost*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Airfreight Cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.01","placeholder":"Enter Airfreight Cost"},model:{value:(_vm.form.airfreight_cost),callback:function ($$v) {_vm.$set(_vm.form, "airfreight_cost", $$v)},expression:"form.airfreight_cost"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('br'),_c('h2',{staticClass:"shipment_create_header"},[_vm._v("Additional cost")]),_c('br'),_c('br'),_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.additionalcosts),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,staticClass:"pt-2",attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Description*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Amount*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter amount","type":"number","step":"0.00"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right minus_button_margin",attrs:{"lg":"2"}},[_c('b-button',{attrs:{"variant":"none"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('b-img',{attrs:{"src":require("@/assets/images/Group.png")}})],1)],1)],1)}),1)],1),_c('br'),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"form_submit_button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.repeateAgain1()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Add cost")])])],1),_c('div',{staticClass:"pt-5 d-flex justify-content-center"},[_c('b-button',{staticClass:"additionalcost_submit_button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.submitData()}}},[_c('span',{staticClass:"additionalcost_submit_button_text"},[_vm._v("Submit")])])],1)]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"pt-3"}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-button',{staticClass:"backbutton",attrs:{"variant":"none"},on:{"click":function($event){return _vm.back()}}},[_c('span',{staticClass:"back_button_text_styles"},[_vm._v("Back")])])],1),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }